<template>
  <div id="app">
    <div v-if="result">
      <img :src="result" />
      <Compute />
    </div>
    <p v-else>Processing...</p>

    <img v-if="source" :src="source" />
  </div>
</template>

<script>
import Compute from "@/components/Compute.vue";

export default {
  components: { Compute },
  name: "ResultOk",
  created() {
    this.getResult();
  },
  data() {
    return {
      source: null,
      result: null,

      scene: null,
      style: null,
      palette: null,
    };
  },
  methods: {
    async getResult() {
      const result = this.$route.query.result;
      const signature = this.$route.query.signature;
      const source = this.$route.query.source;
      const scene = this.$route.query.scene;
      const style = this.$route.query.style;
      const palette = this.$route.query.palette;

      const { data } = await this.$api.get("/status", {
        params: { result, signature, source, scene, style, palette },
      });

      this.source = source;

      if (data) {
        this.result = data.result;
      } else {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.getResult();
      }
    },
  },
};
</script>
