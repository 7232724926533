<template>
  <div class="form-group">
    <FancyButton
      label
      :for="id"
      :loading="loading"
      class="btn-primary btn-lg btn-block cursor-pointer"
    >
      <template v-if="success">{{ successMessage }}</template>
      <template v-else-if="error">{{ errorMessage }}</template>
      <template v-else>{{ defaultMessage }}</template>
    </FancyButton>
    <input
      :id="id"
      type="file"
      class="d-none"
      accept="image/*"
      @input="onFile"
    />
  </div>
</template>

<script>
import FancyButton from "@/components/FancyButton.vue";

export default {
  components: {
    FancyButton,
  },
  props: {
    id: {
      type: String,
      default: () =>
        "dynamic-id-" + Date.now() + `-${Math.random()}`.replace("0.", ""),
    },
    defaultMessage: String,
    successMessage: {
      type: String,
      default: "File has been uploaded successfully",
    },
    errorMessage: {
      type: String,
      default: "Something went wrong",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
    };
  },
  methods: {
    async getSignedUrl(extension) {
      const { data } = await this.$api.post("/upload", {
        extension,
      });

      return data;
    },
    async onFile(e) {
      const [file] = e.target.files;
      if (!file) {
        this.$emit("input");
        return;
      }

      const { name, size } = file;
      const sizeInMB = size / 1000 / 1000;
      if (sizeInMB > 5) {
        this.$toast("Image size should not exceed 5MB", "danger");
        return;
      }

      this.loading = true;

      const extArr = name.split(".");
      const ext = extArr[extArr.length - 1].toLowerCase();

      const { source, url } = await this.getSignedUrl(ext);

      try {
        await this.$api.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        this.success = true;
        this.$emit("input", source);
      } catch (e) {
        this.error = true;
        this.$emit("error");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
