import axios from "axios";

const api = axios.create({
  baseURL: "/api",
});

export default {
  install(Vue) {
    Vue.prototype.$api = api;
  },
};
