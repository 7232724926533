<template>
  <div>
    <select v-model="scene">
      <option disabled>scene</option>
      <option v-for="s in scenes" :key="s">{{ s }}</option>
    </select>
    <select v-model="style">
      <option disabled>style</option>
      <option v-for="s in styles" :key="s">{{ s }}</option>
    </select>
    <select v-model="palette">
      <option disabled>palette</option>
      <option v-for="s in palettes" :key="s">{{ s }}</option>
    </select>
    <FileUpload
      v-if="upload"
      @input="onUpload"
      default-message="Click to upload your drawing"
      success-message="Drawing uploaded successfully"
    />
    <FancyButton
      v-else
      class="btn-lg btn-primary"
      @click="() => onUpload($route.query.source)"
    >
      Redo
    </FancyButton>
  </div>
</template>

<script>
import FancyButton from "./FancyButton.vue";
import FileUpload from "./FileUpload.vue";

export default {
  name: "AB",
  components: {
    FancyButton,
    FileUpload,
  },
  computed: {
    scenes() {
      return ["living room", "dining room", "bedroom", "bathroom"];
    },
    styles() {
      return [
        "modern",
        "contemporary",
        "minimalist",
        "luxurious",
        "traditional",
      ];
    },
    palettes() {
      return ["earthy", "bright"];
    },
  },
  props: {
    upload: Boolean,
  },
  data() {
    return {
      scene: null,
      style: null,
      palette: null,
    };
  },
  created() {
    if (this.upload) {
      this.scene = "living room";
      this.style = "modern";
      this.palette = "earthy";
    } else {
      this.scene = this.$route.query.scene;
      this.style = this.$route.query.style;
      this.palette = this.$route.query.palette;
    }
  },
  methods: {
    async onUpload(source) {
      console.log("source", source);
      const {
        data: { result, signature },
      } = await this.$api.post("/process", {
        source,
        scene: this.scene,
        style: this.style,
        palette: this.palette,
      });

      this.$router.push({
        path: "/result",
        query: {
          source,
          result,
          signature,
          scene: this.scene,
          style: this.style,
          palette: this.palette,
        },
      });

      if (!this.upload) {
        window.location.reload();
      }
    },
  },
};
</script>
