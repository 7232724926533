<template>
  <div id="app">
    <Compute upload />
  </div>
</template>

<script>
import Compute from "@/components/Compute.vue";

export default {
  name: "App",
  components: {
    Compute,
  },
};
</script>
