<template>
  <component
    :is="label ? 'label' : 'button'"
    :class="{
      'btn btn-fancy': true,
      'btn-loading': isLoading,
      disabled: disabled,
    }"
    :disabled="disabled"
    :tabindex="isLoading ? '-1' : null"
    @click.stop="click"
  >
    <span>
      <slot>
        {{ title }}
      </slot>
    </span>
    <IconSpinner />
  </component>
</template>

<script>
import IconSpinner from "@/components/IconSpinner.vue";

export default {
  components: {
    IconSpinner,
  },
  props: {
    label: Boolean,
    title: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: [Boolean, null],
      default: null,
    },
  },
  computed: {
    isLoading() {
      if (this.loading === true) return true;
      else if (this.loading === false) return false;
      else if (this.clicked) return true;
      return false;
    },
  },
  methods: {
    click(e) {
      if (this.disabled) return;
      if (this.loading === null) {
        if (this.clicked) return;
      }

      this.clicked = true;
      this.$emit("click", e);
    },
  },
};
</script>

<style lang="scss">
$svg-size: 1.25rem;

label.btn-fancy {
  margin-bottom: 0;
}

.form-group {
  > .btn-fancy {
    // adjust for heavy borders
    // margin-top: $input-border-width;
    margin-top: 1px;
  }
}

.btn-fancy {
  position: relative;

  &.btn-loading {
    user-select: none;
    pointer-events: none;

    span {
      opacity: 0;
    }

    svg {
      opacity: 1;
      animation: rotate 0.5s linear infinite;
    }
  }

  span {
    opacity: 1;
    transition: all 0.1s ease-out;
  }

  &.btn-primary {
    svg {
      fill: #fff;
    }
  }

  svg {
    opacity: 0;
    top: calc(50% - #{$svg-size * 0.5});
    left: calc(50% - #{$svg-size * 0.5});
    position: absolute;
    height: $svg-size;
    transition: all 0.1s ease-out;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
